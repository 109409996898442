<template>
  <v-col v-if="isSimple" cols="12" lg="4">
    <v-select
      v-model="obModel.frequency_interval"
      :items="items"
      :label="$t('timeperiod.frequency')"
      :menu-props="{ offsetY: true }"
      dense
      outlined
      persistent-hint
    >
      <template v-if="!hideToggleBtn" #append>
        <v-btn color="primary" outlined x-small @click="onToggleAdvanced">
          {{ $t("advanced") }}
        </v-btn>
      </template>
    </v-select>
  </v-col>
  <v-row align="start" v-else>
    <v-col :lg="lg" :md="md" cols="12">
      <time-period-frequency v-model="obModel.frequency" />
    </v-col>

    <v-col :lg="lg" :md="md" cols="12">
      <form-field-text
        v-model="obModel.frequency_interval"
        :hint="$t('timeperiod.frequency.interval.hint')"
        input-type="number"
        label="timeperiod.frequency.interval"
        persistent-hint
      />
    </v-col>

    <v-col v-if="!infinite" :lg="lg" :md="md" cols="12">
      <form-field-text
        v-model="obModel.cycles"
        :hint="$t('timeperiod.cycles.hint')"
        input-type="number"
        label="timeperiod.cycles"
        persistent-hint
      />
    </v-col>

    <v-col :lg="lg - 1" :md="md" cols="12">
      <form-field-text
        v-model="obModel.delay"
        :hint="$t('timeperiod.delay.hint')"
        input-type="number"
        label="timeperiod.delay"
        persistent-hint
      />
    </v-col>

    <v-col cols="6" md="1">
      <v-btn color="primary" outlined x-small @click="onToggleAdvanced">
        {{ $t("simple") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { TimePeriod } from "@planetadeleste/vue-mc-gw";
import TimePeriodFrequency from "@/components/form/fields/TimePeriodFrequency.vue";
import { map, range } from "lodash";

@Component({
  components: { TimePeriodFrequency },
})
export default class TimePeriodField extends Vue {
  @VModel({ type: Object, default: () => new TimePeriod() })
  obModel!: TimePeriod;
  @Prop(Boolean) readonly infinite!: boolean;
  @Prop(Boolean) readonly hideToggleBtn!: boolean;

  bAdvanced = false;

  get lg() {
    return this.infinite ? 4 : 3;
  }

  get md() {
    return this.infinite ? 6 : 4;
  }

  get isSimple() {
    return (
      !this.bAdvanced &&
      this.obModel.frequency === "month" &&
      this.obModel.frequency_interval <= 12
    );
  }

  get items() {
    const arMonths = range(1, 13);
    return map(arMonths, (iMonth) => {
      const sText = iMonth > 1 ? "months" : "n.month";
      return { text: this.$t(sText, { value: iMonth }), value: iMonth };
    });
  }

  onToggleAdvanced() {
    if (this.bAdvanced) {
      this.obModel.set("frequency", "month");

      if (this.obModel.frequency_interval > 12) {
        this.obModel.set("frequency_interval", 1);
      }
    }

    this.bAdvanced = !this.bAdvanced;
  }

  mounted() {
    if (this.obModel && !(this.obModel instanceof TimePeriod)) {
      this.$emit("input", new TimePeriod(this.obModel));
    }
  }
}
</script>
